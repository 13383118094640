<template>
  <div>
    <div class="body-main">
      <div class="cart-header">
        <div class="cart-header-warp clearfix">
          <div class="cart-title left clearfix">
            <h1 class="left">我的购物车</h1>
            <div class="left js-number-box-cart">共<span class="js-coures-num">{{data.count}}</span>门，已选择<span class="js-choice-num">1</span>门</div>
          </div>
          <!-- 购物车广告位 -->
          <div id="orderCartBanner"></div>
          <div class="right">
            <div class="clearfix">
              <span class="left"><a class="js-myorderbtn myorder-history" href="#/myoder">我的订单历史</a></span>
              <span class="left num js-order-num" style="display: inline">1</span>
            </div>
          </div>
        </div>
      </div>
      <div class="cart-body" id="cartBody">
        <div class="cart-body-title clearfix">
          <div class="item-1 l">
            <i @click="tabAll" :class="{ 'imv2-checkbox_onk':(!all), 'js-check-all':(all),'imv2-checkbox':(all) }" class="check" data-selected="all"></i>
            <span class="c-93999f">全选</span>
          </div>
          <div class="newitem-2 l clearfix"><span class="c-4d555d l">课程</span></div>
          <div class="item-3 l"><span class="c-4d555d">金额</span></div>
          <div class="item-4 l"><span class="c-4d555d">操作</span></div>
        </div>
        <div id="js-cart-body-table" class="cart-body-table">
          <div v-for="(v,i) in tableData" :key="i" class="item clearfix js-item-cart js-each-549 js-every-2677">
            <div class="item-1">
              <i @click="tables(v.name)" :class="{ 'imv2-checkbox_onk':(chenck.indexOf(v.name)==-1),  'js-check-all':(chenck.indexOf(v.name)!==-1),  'imv2-checkbox':(chenck.indexOf(v.name)!==-1) }" class="check" data-price="299.00" data-discount="258.00" data-endtime="1644335999" data-show="1"></i>
            </div>
            <div class="item-2 clearfix">
              <a href="" target="_blank" class="img-box l"><img :src="v.imagesmall" width="160" height="90"/></a>
              <dl class="l has-package">
                <a href="" target="_blank">{{v.name}}</a>
                <dd class="disabledTip"></dd>
                <p class="package-item js-package-item" style="display: none"></p>
              </dl>
            </div>
            <div class="item-3">
              <div class="price clearfix">
                <span class="discount-name need-hide" data-index="">到手价</span>
                <span class="discount-price"><em>￥</em><span>{{v.price}}</span></span>
                <span class="init-price need-hide"><em>￥</em><span>{{v.originalprice}}</span></span>
              </div>
            </div>
            <div class="item-4 item-4-1">
              <i class="imv2-close js-close-cart close"></i>
            </div>
          </div>
          <div id="Anchor"></div>
          <div class="cart-body-bot js-cart-body-bot">
            <div class="clearfix cart-body-bot-box">
              <div class="right">
                <div class="clearfix add-coupon-box js-add-coupont">
                  <div class="li-left">
                    <div class="li-2">
                      <span class="topdiv w170">已选择<span class="js-choice-num">{{data.count}}</span>件商品</span>
                      <span class="topdiv w70">总计金额：</span>
                      <span class="price price-red w100 clearfix"><em>￥</em><span class="jsAltogether">{{data.price}}</span></span>
                    </div>
                    <div class="li-2 discount-box">
                      <span class="discount-span redpacket-span" style="display: none"></span>
                    <!--  <span class="discount-span goods-discounts">优惠折扣: <em>-￥{{data.originalprice}}</em></span>-->
                      <span class="discount-span discount-coupon-price" style="display: none"></span>
                    </div>
                    <div class="li-1 js-redpacket"></div>
                    <div class="li-1 js-computed-coupon"></div>
                  </div>
                  <div class="li-3">
                    <span @click="js" class="btn js-go-confrim"> 去结算 </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../ulti/ajax";

export default {
  data(){
    return {
      tableData:[],
      data:{},
      all:false,
      chenck:[],
      selectids:''
    }
  },

  async mounted() {
    await this.getdata();
  },
  async created() {
    document.title='p8课堂-我的购物车';
  },
  methods: {

    getdata() {
      api.getShopcartList().then(response => {
       // console.log("222:"+JSON.stringify(response))
        if (response.data.code == 200) {
          this.tableData = response.data.data.shopcartList;
          this.data=response.data.data;
         // console.log("222:"+JSON.stringify(this.data))
          //this.total = response.total;
        }
      });
    },

    tables(v){
      if(this.chenck.indexOf(v)!==-1){
        this.chenck.splice(this.chenck.indexOf(v),1)
      }else{
        this.chenck.push(v)
      }
      if(this.chenck.length==this.tableData.length){
        this.all = true;
      }else{
        this.all = false;
      }
      this.renderjg()
    },

    tabAll(){
      if(this.all){
        this.chenck = [];
        this.all = false;
      }else{
        this.all = true;
        this.chenck = this.tableData.map(e=>e.title)
      }
      this.renderjg()
    },

    renderjg(){
      let num = 0;
      let ids='';
      this.tableData.map(e=>{
        if(this.chenck.indexOf(e.name)!==-1){
          num += parseFloat(e.price);
          ids=ids+e.id+",";
        }
      })
      this.data.price = num;
      this.data.count = this.chenck.length
      this.selectids=ids;
    },

    //:href="`/learn?id=${i.id}`"
    js(){
      window.location.href = '#/cartOder?ids='+ this.selectids
    }
  },
};
</script>

<style>
@import url('../../assets/css/cart.css');

</style>
